import React from 'react'

const BorderCurvesTop = () => {    
  return (
    <div className="white-curve-border curve-top">
      <svg viewBox="0 0 1000 100" preserveAspectRatio="none" height="20px">
        <path className="shape-fill" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
      </svg>
    </div>                            
  )
}

const BorderCurvesBottom = () => {    
  return (
    <div className="white-curve-border curve-top bottom">
      <svg viewBox="0 0 1000 100" preserveAspectRatio="none" height="20px">
          <path className="shape-fill" d="M1000,4.3V0H0v4.3C0.9,23.1,126.7,99.2,500,100S1000,22.7,1000,4.3z"></path>
      </svg>
    </div>   
  )
}

export { BorderCurvesTop, BorderCurvesBottom }
