import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Waypoint } from 'react-waypoint'
import { animated, useSpring, config } from 'react-spring'

const IconsGrid = ({ gridItems }) => {  
  const {icons, heading, blurb} = gridItems;
  const [on, toggle] = useState(false);
  const fadeIn = useSpring({
    opacity: on ? 1 : 0,
    transform: on ? 'translate3d(0,0,0)' : 'translate3d(0, 50%, 0)',
    config: config.slow
  })
  return (
    <div className="columns is-multiline" 
        style={{
          display: `flex`
    }}>
      <Waypoint 
        bottomOffset="40%"
        onEnter={() => { 
          if (!on) toggle(true);
        }}         
      />
      <div className="column is-half-mobile icons-grid-section icons-grid__intro">
          <h2 className="has-text-weight-semibold " style={{fontSize: '1.5em'}}>
            {heading}
          </h2>
          <p>{blurb}</p>
      </div>
      {icons.map( (item, i) => (
        <div key={i} className="column is-4 is-half-mobile icons-grid-section">
          <section >         
              <animated.div className="has-text-centered" style={fadeIn}>
                <div className="image__wrapper"
                  style={{
                    width: '100px',
                    display: 'inline-block',
                  }}
                >
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
                <p className="item-text">{item.text}</p>
              </animated.div>
          </section>
        </div>
      ))}
    </div>
  )
}

IconsGrid.propTypes = {
  gridItems: PropTypes.shape({
    heading: PropTypes.string, 
    icons: PropTypes.array
  }),
}

export default IconsGrid
