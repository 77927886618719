import React from 'react'
import PropTypes from 'prop-types'

const JobPositions = ({ data }) => (
  <div className="columns position__section">
    {data.map(position => (
      <div key={position.position} className="column">
        <section className="section position__position">
          <h4 className="has-text-weight-semibold is-size-4 position__position--title">
            {position.position}
          </h4>                          
          <ul className="position__position--list">            
            {position.items.map(item => (
                <li  key={item} >                  
                  <ul>
                    {item.item.map(item => ( 
                        <li key={item} className="is-size-6">
                        {item}
                      </li>
                    ))}
                  </ul>                  
                </li>
            ))}            
          </ul>
          <div className="position__position--btn btn-wrapper">            
            <a className="btn-cta" href={`${position.buttonurl}`}>{position.buttontitle}</a>
          </div>
        </section>
      </div>
    ))}
  </div>
)

JobPositions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string,    
      buttontitle: PropTypes.string,
      buttonurl: PropTypes.string,      
      items: PropTypes.array,
    })
  ),
}

export default JobPositions
