import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import TwoColumn from '../components/TwoColumn'
import IconsGrid from '../components/IconsGrid'
import JobPositions from '../components/JobPositions'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { BorderCurvesTop, BorderCurvesBottom } from '../components/BorderCurves'
import PreviewCompatibleBgImage from '../components/PreviewCompatibleBgImage'

export const CareersPageTemplate = ({
  title,
  metadescription,
  heading,
  twocolumn,
  careerslearn,
  getinearly,
  iconsgrid,
  perks,
  jobpositions,
  helmet,
}) => (
  <div className="meetus-pg careers-pg ">  
    {helmet || ''}    
    <section className="section section--gradient">
      <div className="container">
        <div className="section" style={{padding: '0'}}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">  
                <div className="container">                  
                  <div className="hero-text"
                    style={{
                      display: 'flex',
                      maxWidth: '400px',
                      lineHeight: '1',
                      alignItems: 'left',
                      flexDirection: 'column',
                    }}> 
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                      <ul>
                        <li><a href="/">Human</a></li>
                        <li><span>Careers</span></li>
                      </ul>
                    </nav> 
                    <h1
                      className="has-text-weight-bold"
                      style={{            
                        lineHeight: '1.2',
                        padding: '0.25em',
                        fontSize: '2em !important'
                      }}            
                    >   {heading} 
                    </h1>                    
                  </div>
                </div>      
                <div className="columns casestudy-section">
                  <div className="column is-10 is-offset-1 two-column-reverse">
                    <TwoColumn gridItems={twocolumn} />
                  </div>
                </div>    
                <div className="intro-section-wrapper icons-grid" >      
                  <div className="hm-full-width half-circle-wrapper">
                    <div className="half-circle" style={{ backgroundImage: `url('/img/meetus-blue-half-circle.png')`}}></div>
                  </div>
                  <div className="column" >                      
                      <IconsGrid gridItems={iconsgrid} />
                  </div>                  
                </div>   
                <PreviewCompatibleBgImage
                  bgTag="div"
                  bgClass="hm-full-width community"
                  imageInfo={careerslearn.bgimage}
                  style={{            
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`,                        
                  }}
                >                                  
                  <BorderCurvesTop />   
                  <div className="columns" style={{margin:'30px 0'}}>
                    <div className="column is-10 is-offset-1">
                      <div className="columns">
                        <div className="column is-5 careerslearn__text">                        <div>
                            <h3 className="careerslearn__title">{careerslearn.title}</h3>
                            <p className="careerslearn__description">{careerslearn.description}</p>
                            <div className="border-line"></div>
                          </div>                          
                        </div>                        
                        <div className="column is-8 has-text-centered careerslearn__img-wrap">   
                          <div className="careerslearn__image">
                            <PreviewCompatibleImage imageInfo={careerslearn} />
                          </div>
                        </div>
                      </div>
                      </div>                    
                  </div>  
                  <BorderCurvesBottom />                
                </PreviewCompatibleBgImage>                 
                <div className="hm-full-width intro-section-wrapper icons-grid" >      
                  <div className="hm-full-width half-circle-wrapper">
                    <div className="half-circle" style={{ backgroundImage: `url('/img/meetus-blue-half-circle.png')`}}></div>
                  </div>
                  <div className="column" >                      
                      <IconsGrid gridItems={perks} />
                  </div>                  
                </div>   
                <PreviewCompatibleBgImage
                  bgTag="div"
                  bgClass="hm-full-width community"
                  imageInfo={getinearly.bgimage}
                  style={{            
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`,                        
                  }}
                >              
                  <BorderCurvesTop />   
                  <div className="columns">
                    <div className="column is-10 is-offset-one-third">
                      <div className="columns">
                        <div className="column is-5 has-text-centered community__text">
                          <h3 className="community__title">{getinearly.title}</h3>
                          <p className="community__description">{getinearly.description}</p>
                          <div className="border-line"></div>
                        </div>                        
                      </div>
                    </div>
                  </div>  
                  <BorderCurvesBottom />
                </PreviewCompatibleBgImage> 
                <div className="columns">
                  <div className="column is-12">
                    <JobPositions data={jobpositions.positions} />
                  </div>
                </div>             
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

CareersPageTemplate.propTypes = {  
  title: PropTypes.string,
  metadescription: PropTypes.string,
  heading: PropTypes.string,
  twocolumn: PropTypes.array,
  careerslearn: PropTypes.object,
  getinearly: PropTypes.object,
  iconsgrid: PropTypes.shape({
    heading:  PropTypes.string,
    blurb:  PropTypes.string,
    icons: PropTypes.array,
  }),  
  perks: PropTypes.shape({
    heading:  PropTypes.string,
    blurb:  PropTypes.string,
    icons: PropTypes.array,
  }),  
  jobpositions: PropTypes.shape({
    positions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  
  helmet: PropTypes.object,
}

const CareersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
      <Layout>
        <CareersPageTemplate
          title={frontmatter.title}      
          metadescription={frontmatter.metadescription}
          heading={frontmatter.heading}      
          twocolumn={frontmatter.twocolumn}
          careerslearn={frontmatter.careerslearn}
          getinearly={frontmatter.getinearly}
          iconsgrid={frontmatter.iconsgrid}
          perks={frontmatter.perks}
          jobpositions={frontmatter.jobpositions}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${frontmatter.title}`}</title>        
              <meta name="description" content={`${frontmatter.metadescription}`} />      
            </Helmet>
          }                    
        />
      </Layout>
  )
}

CareersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default CareersPage

export const pageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
      frontmatter {
        title
        metadescription
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        twocolumn {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text          
        }
        careerslearn {
          bgimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
        }       
        mainpitch {
          title
        } 
        getinearly {    
          bgimage {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }          
          title
          description
        }       
        iconsgrid {
          heading
          blurb
          icons {
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
        }          
        perks {
          heading
          blurb
          icons {
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
        }
        jobpositions {          
          positions {
            position            
            buttontitle
            buttonurl
            items {
              item
            }            
          }
        }              
      }
    }
  }
`
